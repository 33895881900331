export class MCPersonal {
    agrupa_ap_mat: string;
    agrupa_ap_pat: string;
    agrupa_curp: string;
    agrupa_dom: string;
    agrupa_email: string;
    agrupa_f_nac: string;
    agrupa_nombre: string;
    agrupa_nss: string;
    agrupa_rfc: string;
    agrupa_sexo: string;
    agrupa_tel: string;
    ap_materno: string;
    ap_paterno: string;
    codigo: number;
    curp: string;
    estado: string;
    f_nacimiento: Date;
    fecha: Date;
    id_cliente: string;
    id_dom: number;
    id_email: number;
    id_janis: number;
    id_tel: number;
    id_vtex: number;
    mensaje: string;
    nombre: string;
    nss: string;
    req_ap_mat: number;
    req_ap_pat: number;
    req_curp: number;
    req_f_nacimiento: number;
    req_id_dom: number;
    req_id_email: number;
    req_id_tel: number;
    req_nombre: number;
    req_nss: number;
    req_rfc: number;
    req_sexo: number;
    rfc: string;
    sexo: string;
    statuscode: number;
    tipo_cuenta: string;
}